<template>
    <div class="scorers" :key="list.listId" v-for="list in scorers.lists">
        <h3>List {{ list.listId }}</h3>
        <div v-if="list.scorers.length === 0">
            <p>None</p>
        </div>
        <div v-else class="scorer" :key="scorer.nhlPlayerId" v-for="scorer in list.scorers">
            <div class="scorer__team">
                <img :src= "`teamLogos/${scorer.team}.png`" :alt="`${scorer.team} logo`" class="vertical-middle"/>
                {{scorer.firstName}} {{ scorer.lastName }}
            </div>
            <div class="scorer__available">
                <span>Available Times:</span>
                <span :key="availableTime" v-for="availableTime in scorer.availableTimes">
                    {{ availableTime }}
                </span>
            </div>
        </div>
    </div>

    
</template>

<script>
export default {
    name: 'Scorers',
    props: [ 'scorers' ],
    setup() {}
}
</script>
<style scoped>
.scorers {
    margin-bottom: 1rem;
}
.scorers h3 {
    font-size: 1rem;
}
.scorers img {
    width: 20px;
    margin-right: 5px;
}
.scorers .scorer {
    margin-left: 0.5rem;
    margin-bottom: 0.3rem;
}
.scorers .scorer__available {
    font-size: 0.85rem;
    color: var(--grey-4);
}
.scorers .scorer__available span {
    padding-right: 7px;
}
.dark-theme .scorers .scorer__available {
    color: var(--grey-2);
}
.dark-theme .scorers img {
    padding: 1px;
    background-color: var(--white);
    border-radius: 15px;
}
</style>