<template>
<td class="sticky-col text-left">
    <div class="player-name player-link">
        <img :src= "`teamLogos/${playerLine.team}.png`" :alt="`${playerLine.team} logo`" class="vertical-middle">
        <a class="vertical-middle" :href="[ url ] + playerLine.nhlPlayerId" target="_blank">{{ getPlayerName }}</a>
    </div>
    </td>
    <td class="col-1 text-center">
        <div>{{ playerLine.line }}</div>
    </td>
    <td class="col-2 text-left">
        <div>{{ playerLine.linemates }}</div>
    </td>
    <td class="col-3 text-center">
        <div>{{ playerLine.ppLine }}</div>
    </td>
    <td class="col-4 text-left">
        <div>{{ playerLine.ppLinemates }}</div>
    </td>
</template>

<script>
import { computed } from 'vue';

export default {
    name: 'PlayerLine',
    props: ['playerLine'],
    setup(props) {
        const url = "https://www.nhl.com/player/";

        const getPlayerName = computed(() => {
            let firstName = props.playerLine.firstName
            let lastName = props.playerLine.lastName
            let firstCharactor = firstName.substring(0, 1)

            let fullName = firstCharactor + ". " + lastName

            return fullName
        })

        return { url, getPlayerName }
    }
}
</script>