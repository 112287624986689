<template>
    <main class="container body-content">
        <section class="scoreboard">
            <div class="section-title display-flex">
                <h1 class="section-title__label">Scoreboard</h1>
            </div>
            <p>All times are Eastern Time Zone.  Game day changes at 3 AM ET.</p>
            <Spinner v-if="getSpinnerState" />
            <div v-else-if="`${getErrorStatus}` === '200'">
                <div class="scoreboard__nogame display-flex item-center" v-if="getGames.length === 0">
                    <span class="material-icons error-sign">error</span>
                    <p>There are no games today</p>
                </div>
                <div v-else>
                    <!-- <div class="countdown">
                        <span>This page will refresh in {{minutes}} : {{seconds.toString().padStart(2, '0')}}</span>
                    </div> -->
                    <AdComponent :adid="'scoreboard-today'" />
                    <div class="scoreboard__content">
                        <h2>Scorers</h2>
                        <Scorers :scorers="getScorers" />
                    </div>
                    <div class="scoreboard__content">
                        <h2>Today's Games</h2>
                        <Games :games="getGames"/>                    
                    </div> 
                </div> 
                <div class="scoreboard__content">
                    <AdComponent :adid="'scoreboard-yesterday'" />
                    <h2>Yesterday's Scorers</h2>
                    <Scorers :scorers="getYesterdayScorers" />
                    <AdComponent :adid="'scoreboard-bottom'" />
                </div>

            </div>          
            <div class="scoreboard__error text-center" v-else>
                <span class="material-icons error-sign">error</span>
                <p class="error-message">Unable to load Scoreboard. Try again later.</p>
            </div>
        </section>
    </main>
</template>

<script>
// import { onMounted, onBeforeUnmount, ref, watch } from "vue";
import { onMounted } from "vue";
import { useStore } from 'vuex';
import { computed } from 'vue';
import Spinner from "@/components/Spinner";
import Scorers from "@/components/Scorers";
import Games from "@/components/Games";
import AdComponent from "@/components/AdComponent";
export default {
    name: "Scoreboard",
    components: {
        Spinner,
        Scorers,
        Games,
        AdComponent,
    },
    meta: {
        title : "Tim Horton's Hockey Challenge Helper - Scoreboard"
    },
    setup() {
        const store = useStore();
        // const minutes = ref(1);
        // const seconds = ref(0);
        // const intervalId = ref(null);

        onMounted(() => {
            callLiveDataAction()
        })
        // onBeforeUnmount(() => {
        //     stopTimer();
        // })

        const callLiveDataAction = () => {
            store.dispatch("getLiveStats")
        }

        const getErrorStatus = computed(() => {
            return store.state.error
        })

        const getGames = computed(() => {
            return store.state.games
        })

        const getScorers = computed(() => {
            return store.state.scorers
        })

        const getYesterdayScorers = computed(() => {
            return store.state.yesterdayScorers
        })

        const getSpinnerState = computed(() => {
            return store.state.spinner
        })

        // const startTimer = () => {
        //     intervalId.value = setInterval(() => {
        //         if (seconds.value === 0) {
        //             if (minutes.value === 0) {
        //                 // after 2 min, clear interval
        //                 clearInterval(intervalId.value);
        //                 // call server again and refresh the page
        //                 callLiveDataAction();
        //                 return;
        //             }
        //             minutes.value--;
        //             seconds.value = 59;
        //         }
        //         else {
        //             seconds.value--;
        //         }
        //     }, 1000);
        // };

        // watch(getErrorStatus, (error) => {
        //     if (error === '200') {
        //         startTimer();
        //     }
        // });

        // const stopTimer = () => {
        //     clearInterval(intervalId.value);
        // }

        const setErrorStatu = () => {
            store.dispatch('setErrorStatus', '0')
        }

        return { getErrorStatus, getGames, getScorers, getYesterdayScorers, getSpinnerState, setErrorStatu }
    }
}
</script>

<style scoped>
.scoreboard h2 {
    font-size: 1.2rem;
    color: var(--grey-6);
    margin-bottom: 0.4rem;
}
.scoreboard .scoreboard__content {
    margin-bottom: 1.5rem;
}
.scoreboard .scoreboard__nogame {
    margin-bottom: 1.5rem;
}
.scoreboard .scoreboard__nogame .error-sign {
    padding-right:  5px;
}
.scoreboard .scoreboard__nogame p {
    font-weight: 700;
    font-size: 1.2rem;
}
.scoreboard .scoreboard__error .error-sign {
  font-size: 3rem;
}
.dark-theme .scoreboard h2, .dark-theme .scoreboard {
    color: var(--white);
}
</style>