<template>    
    <div class="player-list" :key="playerList.id" v-for="playerList in playerData">
        <PlayerList :playerList="playerList" />
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue';
import PlayerList from "@/components/PlayerList";

export default {
    name: 'PlayerLists',
    components: {
        PlayerList
    },
    setup() {
        const store = useStore();

        const playerData = computed(() => {
            return store.state.picksData
        })

        return { playerData }
    }
}
</script>

<style>
    .player-list {
        margin: 2rem 0;
    }
    .player-list .player-list-header {
        background-color: var(--red-6);
        border: 1px solid var(--red-6);
        color: var(--white);
        padding: 2px 6px;
    }
    .player-list .player-list-header .material-icons {
        font-size: 30px;
        font-weight: bold;
    }
    .players {
        overflow-y: hidden;
        overflow-x: auto;
    }
    .players .player-table {
        border: 1px solid var(--red-6);
        overflow-y: hidden;
        overflow-x: auto;
        margin-bottom: 30px;
    }
</style>