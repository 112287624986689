<template>
    <div class="player-list-header display-flex flex-row justify-content-space-between cursor-pointer" :class="[getListStatus(playerList.id) ? 'show-list' : 'hide-list']" @click="setListStatus(playerList.id)">
        <h2>Pick # {{ playerList.id }}</h2>
        <span class="material-icons">expand_more</span>
    </div>

    <div class="players" :class="[getListStatus(playerList.id) ? 'show-list' : 'hide-list']" ref="contentRef">
        <div class="player-table">
            <table role="table">
                <thead>
                    <tr>
                        <th class="sticky-col text-left" @click="sortTable('fullName')">
                            <div class="player-name">
                                <span>Name</span>
                                <span v-if="sortColumn === 'fullName'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th class="col-1" @click="sortTable('position')">
                            <div>
                                <span>Pos</span>
                                <span v-if="sortColumn === 'position'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th class="col-2" @click="sortTable('gamesPlayed')">
                            <div>
                                <span>GP</span>
                                <span v-if="sortColumn === 'gamesPlayed'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th class="col-3" @click="sortTable('goals')">
                            <div>
                                <span>G</span>
                                <span v-if="sortColumn === 'goals'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th class="col-4" @click="sortTable('shotsPerGame')">
                            <div>
                                <span>Shots/GP</span>
                                <span v-if="sortColumn === 'shotsPerGame'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th class="col-5" @click="sortTable('shootingPercentage')">
                            <div>
                                <span>Shoot%</span>
                                <span v-if="sortColumn === 'shootingPercentage'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th class="col-6" @click="sortTable('powerPlayTimeOnIcePerGame')">
                            <div>
                                <span>PP.TOI/GP</span>
                                <span v-if="sortColumn === 'powerPlayTimeOnIcePerGame'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th class="col-7" @click="sortTable('timeOnIcePerGame')">
                            <div>
                                <span>TOI/GP</span>
                                <span v-if="sortColumn === 'timeOnIcePerGame'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th class="col-8" @click="sortTable('goalsPerGame')">
                            <div>
                                <span>Goals/GP</span>
                                <span v-if="sortColumn === 'goalsPerGame'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th class="col-1" @click="sortTable('line')">
                            <div>
                                <span>EV Line</span>
                                <span v-if="sortColumn === 'line'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th class="col-1" @click="sortTable('ppLine')">
                            <div>
                                <span>PP Line</span>
                                <span v-if="sortColumn === 'ppLine'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th class="col-9" @click="sortTable('homeAway')">
                            <div>
                                <span>H/A</span>
                                <span v-if="sortColumn === 'homeAway'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th class="col-10" @click="sortTable('opponentGAA')">
                            <div>
                                <span>Opp.GAA</span>
                                <span v-if="sortColumn === 'opponentGAA'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="player in playerList.players" :key="player.nhlPlayerId" :class="{highlight : player.unavailable}">
                        <Player :player="player" />
                    </tr>
                </tbody>
            </table>
        </div>
        <AdComponent :adid="playerList.id"/>
    </div>
</template>
   
<script>
import { useStore } from 'vuex'
import { ref, onMounted, watch } from "vue";
import Player from "../components/Player";
import AdComponent from "@/components/AdComponent";
   
export default {
    name: 'PlayerList',
    props: ['playerList'],
    components: {
        Player,
        AdComponent
    },
    setup(props) {
        const store = useStore();
        const sortColumn = ref('');
        const ascending = ref(true);

        // click table header to sort the list
        const sortTable = (col) => {
            if (ascending.value)  {
                ascending.value = false;
            }
            else {
                ascending.value = !ascending.value;
            }
            sortColumn.value = col;

            let playerlist = props.playerList.players;
            playerlist.sort((a, b) => {
                if (a[col] > b[col]) {
                    return ascending.value ? 1 : -1
                }
                else if (a[col] < b[col]) {
                    return ascending.value ? -1 : 1
                }

                return 0;
            })
        }

        const getListStatus = (listIndex) => {
            return store.getters.getListStatus(listIndex);
        }

        const setListStatus = (listNumber) => {
            store.dispatch('setListStatus', listNumber)
        }

        // sort table by goals/GP when the site loads
        onMounted(() => {
            sortTable('goalsPerGame');
        })

        // call sortTable function when the props change
        // this is for filter menu, change season, seasonType, and dataScope
        watch(() => props.playerList, () => {
            ascending.value = true
            sortTable('goalsPerGame')
        })

        return { sortColumn, ascending, getListStatus, setListStatus, sortTable }
    }
}
</script>
   
<style>
    table {
        background-color: var(--white);
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        white-space: nowrap;
    }
    th, td {
        border-left: 1px solid var(--grey-1);
        padding: 0;
        font-size: 14px;
        color: var(--grey-5);
    }
    th {
        cursor: pointer;
    }
    th > div, td > div {
        padding: 6px;
    }
    th .sort-icon.sort-ase:after, th .sort-icon.sort-desc:after {
        content: " ";
        position: relative;
        left: 4px;
        border: 5px solid transparent;
    }
    th .sort-icon.sort-ase:after {
        bottom: 12px;
        border-bottom-color: var(--grey-5);
    }
    th .sort-icon.sort-desc:after {
        top: 12px;
        border-top-color: var(--grey-5);
    }
    .sticky-col {
        position: sticky;
        background-color: var(--white);
        left: 0;
        z-index: 10;
        width: 155px;
        min-width: 155px;
    }
    tbody tr:nth-child(odd), tbody tr:nth-child(odd) .sticky-col {
        background-color: var(--grey-0)
    }
    thead tr {
        border-bottom: 2px solid var(--grey-5);
    }
    tbody tr.highlight, tbody tr.highlight .sticky-col {
        background-color: var(--red-3);
    }
    .players .player-name {
        border-right: 1px solid #707070;
        width: 155px;
    }

    /* toggle list animation */
    .players.show-list {
        transition: max-height .3s ease-out;
    }
    .players.hide-list {
        max-height: 0;
        transition: max-height .3s ease-in;
    }

    /* rotate arrow icons */
    .player-list-header.show-list .material-icons {
        transform: rotate(180deg);
        transition: transform .5s linear;
    }
    .player-list-header.hide-list .material-icons {
        transform: rotate(0deg);
        transition: transform .5s linear;
    }

    .dark-theme tbody tr:nth-child(odd), .dark-theme tbody tr:nth-child(odd) .sticky-col {
        background-color: #2d2c2c;
    }
    .dark-theme tbody tr:nth-child(even) .sticky-col, .dark-theme thead tr .sticky-col {
        background-color: #4c4c4c;
    }
    .dark-theme tbody tr.highlight, .dark-theme tbody tr.highlight .sticky-col {
        background-color: #b9352f;
    }
</style>