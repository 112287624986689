<template>
    <section>
        <div class="filter-tabs display-flex">
            <div class="filter">
                <input type="date" class="filter-item filter-date cursor-pointer" v-model="date" @click="OpenDatePicker" @change="setHistoryDateFilter($event.target.value)" :min ="minDate" :max="maxDate" />
            </div>
            <div class="filter">
                <select name="time" class="filter-item filter-selection cursor-pointer" @change="setHistoryTimeFilter($event.target.value)">
                    <option value="all">All</option>
                    <option :value="time" v-for="time, index in getHistoryTime" :key="index">{{ time }}</option>
                </select>
            </div>
            <div class="filter">
                <span class="switch-label">Only show scorers</span>
                <label class="switch">
                    <input type="checkbox"  @click="toggleScorersFilter(!scorersFilterChecked)" v-model="scorersFilterChecked"/>
                    <span class="slider"></span>
                </label>
            </div>
        </div>
    </section>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "HistoryFilter",
    setup() {
        const store = useStore();

        const getHistoryTime = computed(() => {
            return store.state.historyTime
        })

        const toggleScorersFilter = (scorersFilter) => {
            store.dispatch('setHistoryScorersFilter', scorersFilter)
        }

        const setHistoryTimeFilter = (time) => {
            store.dispatch('setHistoryTimeFilter', time)
        }

        const setHistoryDateFilter = (date) => {
            store.dispatch("setHistoryErrorStatus", '')
            store.dispatch('setHistoryDateFilter', date)
            store.dispatch('getHistory')
        }


        const calcDate = new Date();
        calcDate.setDate(calcDate.getDate() - 1);
        const month = (calcDate.getMonth() + 1);
        const day = calcDate.getDate();
        const date = calcDate.getFullYear() + '-' + month.toString().padStart(2, "0") + '-' + day.toString().padStart(2, "0");
        const maxDate = date;
        const minDate = new Date('2022-01-01').toISOString().substr(0, 10);
        store.dispatch('setHistoryDateFilter', date)
        store.dispatch('getHistory')

        const OpenDatePicker = (e) => {
            e.currentTarget.showPicker()
        }

        return { getHistoryTime, toggleScorersFilter, setHistoryTimeFilter, setHistoryDateFilter, date, maxDate, minDate, OpenDatePicker }
    }
}
</script>

<style scoped>
.filter-tabs {
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
}

.filter .filter-item {
    border: 1px solid var(--red-6);
    font-size: 16px;
    color: var(--grey-5);
}

.filter .filter-selection {
    padding: 9.5px;
}

.filter .filter-date {
    padding: 5.5px 9.5px;
    font-family: inherit;
}

.switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 28px;
    margin-left: 7px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 28px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: var(--red-6);
}

input:focus + .slider {
    box-shadow: 0 0 1px var(--red-6);
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.dark-theme .filter .filter-item {
    background-color: #5c5c5c;
    color: var(--white);
}

.dark-theme .filter .switch-label {
    color: var(--white);
}
</style>