<template>
    <div class="player-list" :key="playerList.id" v-for="playerList in gameLogsData">
        <PlayerList :playerList="playerList" />
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue';
import PlayerList from "@/components/PlayerList";

export default {
    name: 'Trend',
    components: {
        PlayerList,
    },
    setup() {
        const store = useStore();

        const gameLogsData = computed(() => {
            return store.getters.getGameLogs
        })

        return { gameLogsData }
    }
}
</script>

<style>

</style>