<template>
    <main class="container body-content">
        <HistoryFilter />
        <div class="text-center" v-if="`${getErrorStatus}` === ''">
            <Spinner />
        </div>
        <div class="site-status text-center" v-else-if="`${getErrorStatus}` === '404'">
            <span class="material-icons error-sign">error</span>
            <p class="error-message">No games or data for selected date.</p>
        </div>
        <div v-else-if="`${getErrorStatus}` === '200'">
            <section>
                <div class="player-list" :key="playerList.id" v-for="playerList in playerLists">
                    <PlayerList :playerList="playerList" />
                </div>
            </section>
        </div>
        <div class="site-status text-center" v-else>
            <span class="material-icons error-sign">error</span>
            <p class="error-message">Something went wrong!  Please try again later.</p>
        </div>
    </main>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import Spinner from "@/components/Spinner";
import HistoryFilter from "@/components/HistoryFilter";
import PlayerList from "@/components/History/PlayerList";

export default {
    name: "History",
    components: {
        Spinner,
        HistoryFilter,
        PlayerList
    },
    meta: {
        title : "Tim Horton's Hockey Challenge Helper - History"
    },
    setup() {
        const store = useStore();

        const playerLists = computed(() => {
            let list = JSON.parse(JSON.stringify(store.state.historyPlayerLists));


            //store.state.historyTimeFilter
            //store.state.historyScorerFilter
            //go through each player in each list to filter players
            list.forEach((playerList) => {
                    
                const filteredPlayerList = [];
                playerList.players.forEach((player) => {
                    let validPlayer = true;

                    //time filter first
                    if ((store.state.historyTimeFilter != 'all') && (!player.availableTimes.includes(store.state.historyTimeFilter))) {
                        validPlayer = false;
                    }

                    //scored filter
                    if (validPlayer && store.state.historyScorersFilter && !player.scored) {
                        validPlayer = false;
                    }

                    if (validPlayer) {
                        filteredPlayerList.push(player);
                    }
                });
                playerList.players = filteredPlayerList;                     
            });
            
            return list;
        })


        const getErrorStatus = computed(() => {
            return store.state.historyError
        })

        return { playerLists, getErrorStatus }
    }
}
</script>

<style>
.player-list {
    margin-top: 2rem;
}
.player-list .player-list-header {
    background-color: var(--red-6);
    border: 1px solid var(--red-6);
    color: var(--white);
    padding: 2px 6px;
}
.player-list .player-list-header .material-icons {
    font-size: 30px;
    font-weight: bold;
}
.players {
    overflow-y: hidden;
    overflow-x: auto;
}
.players .player-table {
    border: 1px solid var(--red-6);
    overflow-y: hidden;
    overflow-x: auto;
}
</style>