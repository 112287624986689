<template>
    <div class="player-list-header display-flex flex-row justify-content-space-between cursor-pointer" :class="[getListStatus(pickLine.id) ? 'show-list' : 'hide-list']" @click="setListStatus(pickLine.id)">
        <h2>Pick # {{ pickLine.id }}</h2>
        <span class="material-icons">expand_more</span>
    </div>
    <div class="players" :class="[getListStatus(pickLine.id) ? 'show-list' : 'hide-list']">
        <div class="player-table">
            <table role="table">
                <thead>
                    <tr>
                        <th class="sticky-col text-left" @click="sortTable('fullName')">
                            <div class="player-name">
                                <span>Name</span>
                                <span v-if="sortColumn === 'fullName'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th class="col-1" @click="sortTable('line')">
                            <div>
                                <span>EV Line</span>
                                <span v-if="sortColumn === 'line'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th class="col-2" @click="sortTable('linemates')">
                            <div>
                                <span>EV Linemates</span>
                                <span v-if="sortColumn === 'linemates'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th class="col-3" @click="sortTable('ppLine')">
                            <div>
                                <span>PP Line</span>
                                <span v-if="sortColumn === 'ppLine'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th class="col-4" @click="sortTable('ppLinemates')">
                            <div>
                                <span>PP Linemates</span>
                                <span v-if="sortColumn === 'ppLinemates'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="playerLine in pickLine.players" :key="playerLine.nhlPlayerId" :class="{highlight : playerLine.unavailable}">
                        <PlayerLine :playerLine="playerLine" />
                    </tr>
                </tbody>
            </table>
        </div>
        <AdComponent :adid="pickLine.id"/>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { ref, onMounted, watch } from "vue";
import PlayerLine from '@/components/PlayerLine.vue';
import AdComponent from "@/components/AdComponent";

export default {
    name: 'PickLine',
    props: ['pickLine'],
    components: {
        PlayerLine,
        AdComponent
    },
    setup(props) {
        const store = useStore();
        const sortColumn = ref('');
        const ascending = ref(false);

        // click table header to sort the list
        const sortTable = (col) => {
            if (ascending.value)  {
                ascending.value = false;
            }
            else {
                ascending.value = !ascending.value;
            }
            sortColumn.value = col;

            let pickLine = props.pickLine.players;
            pickLine.sort((a, b) => {
                if (a[col] > b[col]) {
                    return ascending.value ? 1 : -1
                }
                else if (a[col] < b[col]) {
                    return ascending.value ? -1 : 1
                }

                return 0;
            })
        }

        const getListStatus = (listIndex) => {
            return store.getters.getListStatus(listIndex);
        }

        const setListStatus = (listNumber) => {
            store.dispatch('setListStatus', listNumber)
        }

        // sort table by goals/GP when the site loads
        onMounted(() => {
            sortTable('line')
        })

        // call sortTable function when the props change
        // this is for filter menu, change season, seasonType, and dataScope
        watch(() => props.playerList, () => {
            ascending.value = false
            sortTable('line')
        })

        return { sortColumn, ascending, getListStatus, setListStatus, sortTable }
    }
}
</script>