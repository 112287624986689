<template>
  <div class="container body-content">
    <div class="page-not-found text-center">
      <h1>404</h1>
      <p>Sorry, the page you were looking for could not be found.</p>
      <p>Please go back to the homepage</p>
      <a class="homepage-link" href="/">Go Back Home</a>
    </div>
  </div>
</template>

<style scoped>
  .page-not-found h1 {
    font-size: 10rem;
  }
  .page-not-found .homepage-link {
    background-color: var(--red-6);
    color: var(--white);
    padding: 10px 20px;
    margin-top: 25px;
    display: inline-block;
  }

  .dark-theme h1 {
    color: var(--white);
  }
  .dark-theme p {
    color: #dedede;
  }
  .dark-theme .page-not-found .homepage-link {
    background-color: var(--red-5);
  }
</style>