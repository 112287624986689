<template>
    <div class="warnings" v-show="`${getWarnings.length}` >= 1">
        <div class="warning-title display-flex flex-row justify-content-space-between cursor-pointer" :class="[getWarningStatus ? 'show-list' : 'hide-list']" @click="setWarningStatus">
            <h3>WARNINGS</h3>
            <span class="material-icons">expand_more</span>
        </div>
        <div class="warning-list" :class="[getWarningStatus ? 'show-list' : 'hide-list']">
            <div class="warning" v-for="warning in getWarnings" :key="warning">
                <span class="material-icons warning-sign">error</span>
                <span class="warning-text">{{ warning }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'Warnings',

    setup() {
        const store = useStore();

        const getWarnings = computed(() => {
            return store.state.warnings
        })

        const getWarningStatus = computed(() => {
            return store.getters.getWarningStatus
        })

        const setWarningStatus = () => {
            store.dispatch('setWarningStatus')
        }

        return { getWarnings, setWarningStatus, getWarningStatus }
    }
}
</script>

<style>
    .warnings {
        background-color: var(--red-1);
        border: 1px solid #EBCCD1;
        padding: 2px 6px;
        margin-top: 30px;
        overflow: hidden;
    }
    .warnings h3, .warnings .material-icons {
        color: var(--red-4);
    }
    .warnings .warning-title {
        margin-bottom: 5px;
    }
    .warnings .warning-title .material-icons {
        font-size: 28px;
        font-weight: bold;
    }
    .warnings .warning-sign {
        padding-top: 3px;
        font-size: 20px;
    }
    .warnings .warning-list.show-list {
        overflow-y: auto;
    }
    .warnings .warning {
        display: flex;
        gap: 0 5px;
        margin-top: 2px;
    }
    /* toggle list animation */
    .warnings .warning-list.show-list {
        max-height: 150px;
        transition: max-height .2s ease-out;
    }
    .warnings .warning-list.hide-list {
       max-height: 0;
       transition: max-height .2s ease-in;
    }
    /* rotate arrow icons */
    .warnings .warning-title.show-list .material-icons {
        transform: rotate(180deg);
        transition: transform .5s linear;
    }
    .warnings .warning-title.hide-list .material-icons {
        transform: rotate(0deg);
        transition: transform .5s linear;
    }

    .dark-theme .warnings {
        background-color: #a50f154a;
        color: var(--white);
        border: 1px solid #a50f154a;
    }
    .dark-theme .warnings h3, .dark-theme .warnings .material-icons {
        color: var(--white);
    }
    .dark-theme .warning-text {
        color: #dedede;
    }

</style>