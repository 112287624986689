<template>
    <section class="legend">
        <div class="section-title display-flex">
            <h2 class="section-title__label">Legend</h2>
        </div>
        <div class="content display-flex flex-row">
            <div class="legend-table flex-grow-1">
                <div class="legend-column">
                    <span class="legend-title font-7">Pos</span>
                    <span class="font-7">-</span>
                    <span class="legend-definition">Position</span>
                </div>
                <div class="legend-column">
                    <span class="legend-title font-7">GP</span>
                    <span class="font-7">-</span>
                    <span class="legend-definition">Games Played</span>
                </div>
                <div class="legend-column">
                    <span class="legend-title font-7">G</span>
                    <span class="font-7">-</span>
                    <span class="legend-definition">Goals</span>
                </div>
                <div class="legend-column">
                    <span class="legend-title font-7">Shots/GP</span>
                    <span class="font-7">-</span>
                    <span class="legend-definition">Shots per Game Played</span>
                </div>
                <div class="legend-column">
                    <span class="legend-title font-7">Shoot%</span>
                    <span class="font-7">-</span>
                    <span class="legend-definition">Percentage of shots that score</span>
                </div>
                <div class="legend-column">
                    <span class="legend-title font-7">PP.TOI/GP</span>
                    <span class="font-7">-</span>
                    <span class="legend-definition">Power Play Time on Ice per Game Played</span>
                </div>                
            </div>
            <div class="legend-table flex-grow-1">
                <div class="legend-column">
                    <span class="legend-title font-7">TOI/GP</span>
                    <span class="font-7">-</span>
                    <span class="legend-definition">Time on Ice per Game Played</span>
                </div>
                <div class="legend-column">
                    <span class="legend-title font-7">Goals/GP</span>
                    <span class="font-7">-</span>
                    <span class="legend-definition">Goals per Game Played</span>
                </div>
                <div class="legend-column">
                    <span class="legend-title font-7">Opp.GAA</span>
                    <span class="font-7">-</span>
                    <span class="legend-definition">Opposition Goals Against Average</span>
                </div>
                <div class="legend-column">
                    <span class="legend-title font-7">EV Line</span>
                    <span class="font-7">-</span>
                    <span class="legend-definition">Even Strength Line Number</span>
                </div>
                <div class="legend-column">
                    <span class="legend-title font-7">PP Line</span>
                    <span class="font-7">-</span>
                    <span class="legend-definition">Powerplay Line Number</span>
                </div>
            </div>
            <div>
                    Sources: Stats <a href="https://www.nhl.com/stats/" about="_blank">NHL.com</a> - Injuries <a href="https://www.rotowire.com/hockey/injury-report.php" about="_blank">RotoWire</a> - Lineups <a href="https://www.dailyfaceoff.com/teams" about="_blank">DailyFaceoff</a>
                </div>            
        </div>
    </section>
</template>

<script>

export default {
    name: 'Legend'
}
</script>

<style scoped>
.legend-table .legend-column {
    margin-bottom: 5px;
    display: flex;
    gap: 0 5px;
    color: var(--grey-5);
}
</style>