<template>
    <td>
        <div class="text-center">
            <img v-if="`${player.scored}` === 'true'" src= "icons/red-siren.png" alt="red siren" class="cross-sign vertical-middle" />
            <img v-else src= "icons/cross-sign.png" alt="cross sign" class="cross-sign vertical-middle" />
        </div>
    </td>
    <td>
        <div>
            <img :src= "`teamLogos/${player.team}.png`" :alt="`${player.team} logo`" class="vertical-middle team-logo">
            <!-- <a class="vertical-middle" :href="[ url ] + player.nhlPlayerId" target="_blank">{{ getPlayerName }}</a> -->
            <span>{{ player.fullName }}</span>
        </div>
    </td>
    <td>
        <div class="text-center">
            <img :src= "`teamLogos/${player.opponent}.png`" :alt="`${player.opponentTeam} logo`" class="team-logo vertical-middle">
        </div>
    </td>
    <td>
        <div>
            <span :key="availableTime" v-for="availableTime in player.availableTimes" class="available-time">
                {{ availableTime }}
            </span>
        </div>
    </td>
    <td><div>{{ player.note }}</div></td>
</template>

<script>
import { computed } from 'vue';

export default {
    name: 'Player',
    props: ['player'],
    setup(props) {
        const url = "https://www.nhl.com/player/";

        const getPlayerName = computed(() => {
            let firstName = props.player.firstName
            let lastName = props.player.lastName
            let firstCharactor = firstName.substring(0, 1)

            let fullName = firstCharactor + ". " + lastName

            return fullName
        })
        
        return { url, getPlayerName }
    }
}
</script>

<style scoped>
td {
    border-left: 1px solid var(--grey-1);
    padding: 0;
    font-size: 14px;
    color: var(--grey-5);
}
td > div {
    padding: 6px;
}
/* .players .player-link {
    display: inline-block;
} */
.team-logo {
    width: 22px;
    padding-right: 5px;
}
.cross-sign {
    width: 19px;
}
.available-time:not(:last-child)::after {
    content: ", ";
}
/* .players .sticky-col a {
    color: var(--blue-3);
}
.players .sticky-col a:hover {
    color: var(--dark-blue);
}
.players .col-1 {
    border-left: 0;
} */
</style>