<template>
    <li class="accordion__item">
      <div 
        class="accordion__trigger"
        :class="{'accordion__trigger_active': visible}"
        @click="open">
        <slot name="accordion-trigger"></slot>
      </div>
  
      <transition 
        name="accordion"
        @enter="start"
        @after-enter="end"
        @before-leave="start"
        @after-leave="end">
  
        <div class="accordion__content"
          v-show="visible">
          <div class="accordion__item-body">
            <slot name="accordion-content"></slot>
          </div>
        </div>
      </transition>
    </li>
  </template>
  
  
  <script>
  export default {
    props: {},
    inject: ["Accordion"],
    data() {
      return {
        index: null
      };
    },
    computed: {
      visible() {
        // return true
        return this.index == this.Accordion.active;
      }
    },
    methods: {
      open() {
        if (this.visible) {
          this.Accordion.active = null;
          // this.Accordion.active = this.index;
        }
        else {
          this.Accordion.active = this.index;
          // this.Accordion.active = null;
        }
      },
      start(el) {
        el.style.height = el.scrollHeight + "px";
      },
      end(el) {
        el.style.height = "";
      }
    },
    created() {
      this.index = this.Accordion.count++;
    }
  };
  </script>
  
  <style scoped>
  .accordion__item {
    padding: 10px 0;
    border-bottom: 1px solid #ebebeb;
    position: relative;
  }
  .accordion__trigger {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
  .accordion-enter-active,
  .accordion-leave-active {
    will-change: height, opacity;
    transition: height 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
  }
  
  .accordion-enter-from,
  .accordion-leave-to {
    height: 0 !important;
    opacity: 0;
  }
  .accordion__item-body {
    color: var(--grey-5);
    margin: 15px 0;
  }

  .dark-theme .accordion__item-body {
    color: #dedede;
  }
  </style>