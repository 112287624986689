<template>
  <div class="ring">
      Loading
  <span></span>
</div>
</template>

<script>
export default {
name: 'Spinner',
}
</script>

<style>
.ring
{
  transform:translate(0%,0%);
  width:90px;
  height:90px;
  background:transparent;
  border:3px solid #333333;
  border-radius:50%;
  text-align:center;
  line-height:90px;
  font-size:13px;
  color:var(--red-6);
  letter-spacing:2px;
  text-transform:uppercase;
  text-shadow:0 0 10px var(--red-6);
  box-shadow:0 0 20px rgba(0,0,0,.5);
  margin: 40px auto 0 auto;
}
.ring:before
{
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid var(--red-6);
  border-right:3px solid var(--red-6);
  border-radius:50%;
  animation:animateC 2s linear infinite;
}
.ring span
{
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation:animate 2s linear infinite;
}
.ring span:before
{
  content:'';
  position:absolute;
  width:14px;
  height:14px;
  border-radius:50%;
  background:var(--red-6);
  top:-6px;
  right:-8px;
  box-shadow:0 0 20px var(--red-6);
}
@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}
</style>