<template>
	<main class="container body-content">
		<section class="supportus">
			<div class="section-title display-flex">
				<h1 class="section-title__label">Support Us</h1>
			</div>
			<div class="content">
				<p>Thank you for using Hockey Challenge Helper and thanks for your interest in keeping it running.</p>
				<section>
					<article>
						<h2>The best ways to support us:</h2>
						<ul>
							<li>Tell your friends and family about Hockey Challenge Helper.</li>
							<li>If you have ad-block enabled, turn it off for the site and leave them open.  Ads are the primary way of paying for server costs.</li>
							<li>Send us your suggestions and feedback.  Dark Mode was the first big feature implemented after the feedback form was created.</li>
							<li>If your company needs an application built send us a message in the <a class="page-link" href="feedback" target="_blank">feedback page</a> about your needs and your contact info.</li>
							<li>Check out our partner websites, and make sure to use our coupon code (if applicable).</li>
						</ul>
					</article>
				</section>
				<section>
					<article>
						<h2>Our Partners:</h2>
						<div class="partners">
							<a href="https://bichonboutique.com?sca_ref=3251555.GRcbcuwfHb&utm_source=timhockey&utm_medium=cpc&utm_campaign=affiliate" target="_blank">
								<img src="partners/bichonboutique.jpg" alt="Bichon Boutique">
							</a>
						</div>
					</article>
				</section>
			</div>
		</section>
	</main>
</template>

<script>
	export default {
        name: "SupportUs",
        meta: {
            title: "Tim Horton's Hockey Challenge Helper - Support Us"
        },
	};
</script>

<style scoped>
    .supportus h2 {
		font-size: 1.2rem;
		color: var(--grey-6);
	}
	.supportus ul li {
		list-style: disc;
		margin: 3px 0;
		margin-left: 2rem;
	}
	.supportus .page-link {
		text-decoration: underline;
	}
	.supportus .partners {
		margin-top: 1rem;
	}
	.dark-theme .supportus, .dark-theme h2 {
		color: var(--white);
	}
</style>
