<template>
    <p>Projected Lineups. Lineups are subject to change as late as just before puck drop.</p>
    <div class="player-list" :key="pickLine.id" v-for="pickLine in lineData">
        <PickLine :pickLine="pickLine" />
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import PickLine from '@/components/PickLine.vue';

export default {
    name: 'PickLines',
    props: ['pickLines'],
    components: {
        PickLine
    },
    setup() {
        const store = useStore();

        const lineData = computed(() => {
            return store.state.picksData
        })

        return { lineData }
    }
}
</script>