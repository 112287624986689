<template>
    <main class="body-content container">
        <section class="contact">
        <section class="content">
            <form action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSeHE-w9kQcJvTOY38POw65KvT9F2Qfzi8gds4AvZkhC6YuGcg/formResponse" target="_self" method="POST">
                <div class="form-group">
                    <label class="form-title">
                        <span class="form-title__label">Feedback type</span>
                        <span class="asterisk">*</span>
                    </label>
                    <div class="form-control">
                        <input type="radio" name="entry.920479785" value="General feedback" id="general-feedback" required>
                        <label for="general-feedback">General feedback</label>
                    </div>
                    <div class="form-control">
                        <input type="radio" name="entry.920479785" value="Bug" id="bug">
                        <label for="bug">Bug</label>
                    </div>
                </div>
                <div class="form-group">
                    <label for="feedback-question" class="form-title">
                        <span class="form-title__label">Feedback or question</span>
                        <span class="asterisk">*</span>
                    </label>
                    <textarea name="entry.162902475" id="feedback-question" required></textarea>
                </div>
                <div class="form-group">
                    <label for="email" class="form-title">
                        <span class="form-title__label">If you want a reply, leave your email below (Optional)</span>
                    </label>
                    <input name="entry.1962094629" type="text" class="short-answer" id="email" />
                </div>
                <div class="form-group">
                    <label class="form-title">
                        <span class="form-title__label">How did you find out about us? (Optional)</span>
                    </label>
                    <div class="form-control">
                        <input type="radio" name="entry.1499365623" value="Reddit" id="reddit">
                        <label for="reddit">Reddit</label>
                    </div>
                    <div class="form-control">
                        <input type="radio" name="entry.1499365623" value="Twitter" id="twitter">
                        <label for="twitter">Twitter</label>
                    </div>
                    <div class="form-control">
                        <input type="radio" name="entry.1499365623" value="Facebook" id="facebook">
                        <label for="facebook">Facebook</label>
                    </div>
                    <div class="form-control">
                        <input type="radio" name="entry.1499365623" value="Search Engine (ie Google)" id="searchEngine">
                        <label for="searchEngine">Search Engine (ie Google)</label>
                    </div>
                    <div class="form-control">
                        <input type="radio" name="entry.1499365623" value="Friends/family/coworker" id="friends">
                        <label for="friends">Friends/family/coworker</label>
                    </div>
                    <div class="form-control">
                        <input type="radio" name="entry.1499365623" value="Other" id="other-option">
                        <label for="other-option" class="margin-right">Other:</label>
                        <input class="other-answer" type="text" name="entry.1891428075" value="">
                    </div>
                </div>
                <div class="form-group">
                    <label for="captcha" class="form-title">
                        <span class="form-title__label">CAPTCHA: 2 + 5 =</span>
                        <span class="asterisk">*</span>
                    </label>
                    <div class="form-control">
                        <span class="margin-right">Answer:</span>
                        <input class="captch-answer" type="text" name="entry.819863928" value="" id="captcha" pattern="[7]{1}" required>
                    </div>
                </div>

                <button class="form-button cursor-pointer" type="submit">Submit</button>
            </form>
        </section>
    </section>
    </main>
</template>

<script>
	export default {
        name: "Contact",
        meta: {
            title: "Tim Horton's Hockey Challenge Helper - Feedback"
        },
	};
</script>

<style scoped>
    .asterisk {
        color: #d93025;
    }
    .contact {
        background-color: #f6f6f6;
        border-radius: 4px;
        padding: 2rem;
    }
    .contact .form-group {
        margin-bottom: 1em;
    }
    .contact .form-title {
        padding-bottom: 5px;
        display: flex;
    }
    .contact .form-title__label {
        color: val(--grey-5);
        font-size: 16px;
        font-weight: 700;
    }
    .contact .form-control {
        margin-bottom: 5px;
    }
    .contact .form-control label {
        padding-left: 0.75em;
    }
    .contact p {
        font-size: 0.8rem;
    }
    .contact textarea {
        padding: 5px;
        font-family: Poppins;
        width: 88%;
        max-width: 377px;
        height: 70px;
        border: 1px solid #dddddd;
    }
    .contact input[type=text] {
        border: 1px solid #dddddd;
        height: 25px;
        padding: 5px;
        font-size: 14px;
    }
    .contact .captch-answer {
        width: 40px;
    }
    .contact .short-answer {
        width: 88%;
        max-width: 286px;
    }
    .contact .other-answer {
        width: 68%;
        max-width: 286px;
    }
    .contact .form-button {
        width: 100%;
        background-color: var(--grey-5);
        color: var(--white);
        font-weight: 700;
        border: 1px solid var(--grey-5);
        padding: 10px;
        margin-top: 20px;
    }
    .contact .margin-right {
        margin-right: 10px;
    }

    .dark-theme .contact {
        background-color: #2d2c2c;
    }
    .dark-theme .contact .form-title__label {
        color: var(--white);
    }
    .dark-theme .contact .form-control {
        color: #dedede;
    }
    .dark-theme .asterisk {
        color: var(--red-5);
    }
    .dark-theme .contact .form-button {
        background-color: #5c5c5c;
        border: 1px solid #5c5c5c;
    }

    @media screen and (min-width: 992px) {
        .contact {
            padding: 2rem 10rem;
        }
        .contact .form-button {
            width: 200px;
        }
    }
</style>