<template>
    <div class="player-list-header display-flex flex-row justify-content-space-between cursor-pointer" :class="[getHistoryListStatus(playerList.id) ? 'show-list' : 'hide-list']" @click="setHistoryListStatus(playerList.id)">
        <h2>Pick # {{ playerList.id }}</h2>
        <span class="material-icons">expand_more</span>
    </div>

    <div class="players" :class="[getHistoryListStatus(playerList.id) ? 'show-list' : 'hide-list']" ref="contentRef">
        <div class="player-table">
            <table>
                <thead>
                    <tr>
                        <th @click="sortTable('scored')">
                            <div>
                                <span>Scored</span>
                                <span v-if="sortColumn === 'scored'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th @click="sortTable('fullName')">
                            <div>
                                <span>Name</span>
                                <span v-if="sortColumn === 'fullName'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th @click="sortTable('opponent')">
                            <div>
                                <span>Opponent</span>
                                <span v-if="sortColumn === 'opponent'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th @click="sortTable('availableTimes')">
                            <div>
                                <span>Available Times</span>
                                <span v-if="sortColumn === 'availableTimes'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                        <th @click="sortTable('note')">
                            <div>
                                <span>Notes</span>
                                <span v-if="sortColumn === 'note'" class="sort-icon" :class="[ascending ? 'sort-ase' : 'sort-desc']"></span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr :key="player.id" v-for="player in playerList.players">
                        <Player :player="player" />
                    </tr>
                </tbody>
            </table>
        </div>
        <AdComponent :adid="'History-' + playerList.id" />
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, watch } from 'vue';
import Player from './Player';
import AdComponent from "@/components/AdComponent";

export default {
    name: 'PlayerLists',
    props: ['playerList'],
    components: {
        Player,
        AdComponent
    },
    setup(props) {
        const store = useStore();
        const sortColumn = ref('');
        const ascending = ref(true);
        // const contentRef = ref(null)
        // const setMaxHeight = ref();
        
        // click table header to sort the list
        const sortTable = (col) => {
            if (ascending.value) {
                ascending.value = false;
            }
            else {
                ascending.value = !ascending.value;
            }
            sortColumn.value = col;

            let playerlist = props.playerList.players;
            playerlist.sort((a, b) => {
                if (a[col] > b[col]) {
                    return ascending.value ? 1 : -1
                }
                else if (a[col] < b[col]) {
                    return ascending.value ? -1 : 1
                }

                return 0;
            })
        }

        const getHistoryListStatus = (listIndex) => {
            return store.getters.getHistoryListStatus(listIndex);
        }

        const setHistoryListStatus = (listNumber) => {
            store.dispatch('setHistoryListStatus', listNumber)
        }

        // onMounted(() => {
        //     setMaxHeight.value = contentRef.value.clientHeight;
        // })

        watch(() => props.playerList, () => {
            ascending.value = true
            sortTable(sortColumn)
        })

        return { sortColumn, ascending, sortTable, getHistoryListStatus, setHistoryListStatus, watch }
    }
}
</script>

<style>
table {
    background-color: var(--white);
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    white-space: nowrap;
}
th {
    border-left: 1px solid var(--grey-1);
    padding: 0;
    font-size: 14px;
    color: var(--grey-5);
}
th {
    cursor: pointer;
}
th > div {
    padding: 6px;
}
th .sort-icon.sort-ase:after, th .sort-icon.sort-desc:after {
    content: " ";
    position: relative;
    left: 4px;
    border: 5px solid transparent;
}
th .sort-icon.sort-ase:after {
    bottom: 12px;
    border-bottom-color: var(--grey-5);
}
th .sort-icon.sort-desc:after {
    top: 12px;
    border-top-color: var(--grey-5);
}
.sticky-col {
    position: sticky;
    background-color: var(--white);
    left: 0;
    z-index: 10;
    width: 155px;
    min-width: 155px;
}
tbody tr:nth-child(odd), tbody tr:nth-child(odd) .sticky-col {
    background-color: var(--grey-0)
}
thead tr {
    border-bottom: 2px solid var(--grey-5);
}
tbody tr.highlight, tbody tr.highlight .sticky-col {
    background-color: var(--red-3);
}

/* toggle list animation */
.players.show-list {
    transition: max-height .3s ease-out;
}
.players.hide-list {
    max-height: 0;
    transition: max-height .3s ease-in;
}

/* rotate arrow icons */
.player-list-header.show-list .material-icons {
    transform: rotate(180deg);
    transition: transform .5s linear;
}
.player-list-header.hide-list .material-icons {
    transform: rotate(0deg);
    transition: transform .5s linear;
}

.dark-theme tbody tr:nth-child(odd), .dark-theme tbody tr:nth-child(odd) .sticky-col {
    background-color: #2d2c2c;
}
.dark-theme tbody tr:nth-child(even) .sticky-col, .dark-theme thead tr .sticky-col {
    background-color: #4c4c4c;
}
.dark-theme tbody tr.highlight, .dark-theme tbody tr.highlight .sticky-col {
    background-color: #b9352f;
}
</style>